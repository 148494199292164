import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import 'pli/pli-card';
import 'pli/pli-text';
import 'pli/pli-button';
import 'pli/pli-user-bubble';
import 'pli/pli-drag-drop';

import { styles } from 'pli/styles';
import { defineHeaderItems } from 'pli/pli-table';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { nameFormatter } from 'utils/name-formatter';
import { downloadContent } from 'utils/download-content';
import { DocumentModel, DocumentPaginatedResponse } from 'schema/document/document-schema';
import { consume } from '@lit/context';
import { confirmServiceContext } from 'context/confirm-service-context';
import { ConfirmService } from 'components/confirmation/confirm-service';
import { Task } from '@lit/task';
import { FileUpdateEvent } from 'pli/pli-drag-drop';
import { UserModel } from 'user-model';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import { errorServiceContext } from 'context/error-service-context';
import { ErrorService } from 'error/error-service';
import { CaseApi, caseApiContext } from 'context/api/case/case-api-context';
import { when } from 'lit/directives/when.js';
import { UploadErrorFileResult } from 'shared/api/upload-file-result';

@customElement('case-details-documents')
class CaseDetailsDocuments extends LitElement {
    static styles = [styles.grid, styles.flex, styles.divider, styles.padding, css``];

    @property({ type: String, reflect: true })
    caseId?: string;

    @property({ type: Boolean, reflect: true })
    disabled?: boolean;

    @consume({ context: caseApiContext })
    _caseApi?: CaseApi;

    @consume({ context: confirmServiceContext })
    confirmService?: ConfirmService;

    @consume({ context: errorServiceContext })
    errorService?: ErrorService;

    @consume({ context: currentUserContext })
    currentUser?: CurrentUser;

    @state()
    _data: DocumentPaginatedResponse | null = null;

    _task = new Task(this, {
        task: async ([]) => {
            if (!this._caseApi) {
                return;
            }

            this._data = await this._caseApi.getDocuments(this.caseId);
        },
        args: () => [] as const,
        autoRun: false,
    });

    _profile = new UserModel();

    async connectedCallback() {
        super.connectedCallback();
        this._task.run();

        if (!this.currentUser) {
            return;
        }

        this._profile = await this.currentUser.get();
    }

    downloadDocument = async (document: DocumentModel) => {
        if (!this._caseApi) {
            return;
        }
        const content = await this._caseApi.downloadDocument(this.caseId, document.documentId);
        downloadContent(content, document.name);
    };

    removeDocument = async (document: DocumentModel) => {
        this.confirmService?.confirm(
            'Deleting document',
            `Are you sure you want to delete the file: ${document.name}. This action cannot be undone.`,
            async () => {
                if (!this._caseApi) {
                    return;
                }
                const data = await this._caseApi.removeDocument(this.caseId, document.documentId);
                if (!data.documentId) return;
                this._task.run();
                this.emit();
            },
        );
    };

    onFilesUpdate = async (event: FileUpdateEvent) => {
        const { value } = event.detail;
        for (const file of value) {
            let form = new FormData();
            form.append('file', file);

            if (!this._caseApi) {
                return;
            }

            const result = await this._caseApi.uploadDocument(this.caseId, form);

            if (result.isError && result instanceof UploadErrorFileResult) {
                this.errorService?.showError(`Upload error for file: ${file.name}`, result.message);
                return;
            }

            this._task.run();
        }

        this.emit();
    };

    onFilesFail = (event: FileUpdateEvent) => {
        const { maximumFileSize } = event.detail;
        this.errorService?.showError(
            'File size limit reached',
            `Attached file(s) exceeds our file size limit (${maximumFileSize} MB), please try to reduce the size of your file or try a different file. `,
        );
    };

    private emit() {
        this.dispatchEvent(new CustomEvent('files-update'));
    }

    render() {
        const { downloadDocument, removeDocument, _data, onFilesUpdate, onFilesFail } = this;
        const hasItems = Boolean(_data?.list.length);
        const headerItems = defineHeaderItems({
            Name: {
                sortField: 'Name',
                columnSpan: 3,
            },
            Date: {
                sortField: null,
                columnSpan: 3,
            },
            'Created by': {
                sortField: null,
                columnSpan: 3,
            },
            '': {
                sortField: null,
                columnSpan: 3,
            },
        });

        const renderItem = (item: DocumentModel): TemplateResult =>
            html` <tr>
                <td>
                    <pli-button variant="text" .onClick="${() => downloadDocument(item)}">
                        <pli-icon slot="icon-left" name="file-earmark-image"></pli-icon>
                        ${item.name}
                    </pli-button>
                </td>
                <td>${dateTimeFormatter(item.uploadedTime.toString())}</td>
                <td>
                    <div class="flex items-center gap-1">
                        <div>
                            <pli-user-bubble
                                userId="${item.uploadedBy.userId}"
                                firstName="${item.uploadedBy.firstName}"
                                lastName="${item.uploadedBy.lastName}"
                            ></pli-user-bubble>
                        </div>
                        <pli-text
                            >${nameFormatter({
                                firstName: item.uploadedBy.firstName,
                                lastName: item.uploadedBy.lastName,
                                userId: item.uploadedBy.userId,
                            })}</pli-text
                        >
                    </div>
                </td>
                <td>
                    ${when(
                        !this.disabled,
                        () =>
                            html`<div class="flex justify-end items-center">
                                <pli-button variant="destructive" size="lg" .onClick="${() => removeDocument(item)}"
                                    ><pli-icon slot="icon-left" name="trash"></pli-icon>Remove</pli-button
                                >
                            </div>`,
                    )}
                </td>
            </tr>`;

        return html`<pli-card>
            <div class="grid-vertical gap-1">
                <div class="grid-vertical gap-1 divider-y">
                    <div class="grid-vertical gap-1">
                        <pli-text as="h4" variant="h4">Documents</pli-text>
                        <pli-text as="p">
                            ${this.disabled
                                ? html`Attached files for your investigation`
                                : html`Attach, view and download any file you need to document your investigation`}
                        </pli-text>
                    </div>
                    ${hasItems
                        ? html`
                              <pli-table
                                  .headerItems="${headerItems}"
                                  .items="${_data?.list ?? []}"
                                  .renderTemplate="${renderItem}"
                              ></pli-table>
                          `
                        : html`<div class="py-4">
                              <div class="grid">
                                  <div class="col-span-6 col-start-4 grid-vertical gap-05">
                                      ${when(
                                          !this.disabled,
                                          () =>
                                              html`<pli-text as="p" variant="h4">No files or documents yet</pli-text>
                                                  <pli-text as="p"
                                                      >However, its very easy to add new ones (Maximum file size: 10 MB)
                                                  </pli-text>`,
                                      )}
                                  </div>
                              </div>
                          </div>`}
                </div>
                ${when(
                    !this.disabled,
                    () =>
                        html`<pli-drag-drop
                            @filesUpdate="${onFilesUpdate}"
                            @filesFail="${onFilesFail}"
                        ></pli-drag-drop>`,
                )}
            </div>
        </pli-card>`;
    }
}
